import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';

import Select from 'react-select'

import 'react-accessible-accordion/dist/fancy-example.css';
import {sortByTitle, sortByLabel, sortStaffByLabel} from "../../utils";
import {ndaStatusColors} from "../../constants";

class EvneProjectsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            projects: [],
            staff: [],
            selectedStaff: [],
            projectsCategories: [],
            projectsCategory: '',
            projectsTypes: [],
            selectedProjectsTypes: [],
            skills: [],
            selectedSkills: [],
            search: '',
            ndaStatusSort: ''
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.projects().on('value', snapshot => {
            const projectsObject = snapshot.val() ? snapshot.val() : [];

            const projects = Object.keys(projectsObject).map(key => ({
                ...projectsObject[key],
                uid: key,
            }));

            projects.sort(sortByTitle);

            this.setState({
                projects: projects,
                loading: false,
            });
        });

        this.props.firebase.projectsCategories().on('value', snapshot => {
            const projectsCategoriesObject = snapshot.val() ? snapshot.val() : [];

            const projectsCategories = Object.keys(projectsCategoriesObject).map(key => ({
                label: projectsCategoriesObject[key].name,
                value: key,
            }));

            projectsCategories.sort(sortByLabel);

            this.setState({
                projectsCategories: projectsCategories,
            });
        });

        this.props.firebase.projectsTypes().on('value', snapshot => {
            const projectsTypesObject = snapshot.val() ? snapshot.val() : [];

            const projectsTypes = Object.keys(projectsTypesObject).map(key => ({
                label: projectsTypesObject[key].name,
                value: key,
            }));

            projectsTypes.sort(sortByLabel);

            this.setState({
                projectsTypes: projectsTypes,
            });
        });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val() ? snapshot.val() : [];

            const skills = Object.keys(skillsObject).map(key => ({
                label: skillsObject[key].name,
                value: key,
            }));

            skills.sort(sortByLabel);

            this.setState({
                skills: skills,
            });
        });

        this.props.firebase.staff().on('value', snapshot => {
            const staffObject = snapshot.val() ? snapshot.val() : [];

            const staff = Object.keys(staffObject).map(key => ({
                label: [staffObject[key].firstName, staffObject[key].lastName].join(' '),
                value: key,
            }));

            staff.sort(sortStaffByLabel);

            this.setState({
                staff: staff,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.projects().off();
        this.props.firebase.staff().off();
        this.props.firebase.categories().off();
        this.props.firebase.skills().off();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    ndaStatusSortChange = event => {
        this.setState({ ndaStatusSort: event.target.value });
    };

    handleInputChange = event => {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;

        let skillsFilter = this.state.skillsFilter;

        if (checked && !skillsFilter.includes(value)) {
            skillsFilter.push(value)
        } else if (!checked){
            skillsFilter = skillsFilter.filter(skill => {return skill !== value})
        }

        this.setState({
            skillsFilter: skillsFilter
        });
    };

    filterProjects = projects => {
        const {search, projectsCategory, selectedSkills, selectedProjectsTypes, selectedStaff, ndaStatusSort} = this.state;
        let filteredProjects = projects.filter(project => {
            return project.title.toLowerCase().includes(search.toLowerCase())
                && this.filterCategory(project, projectsCategory)
                && this.filterProjectSkills(project, selectedSkills)
                && this.filterProjectTypes(project, selectedProjectsTypes)
                && this.filterProjectStaff(project, selectedStaff)
        });

        const sortByNda = (a, b) => {
            if (a.projectNdaStatus && a.projectNdaStatus.value === ndaStatusSort && (!b.projectNdaStatus  || b.projectNdaStatus.value !== ndaStatusSort)) {
                return -1;
            }
            if (b.projectNdaStatus && b.projectNdaStatus.value === ndaStatusSort && (!a.projectNdaStatus  || a.projectNdaStatus.value !== ndaStatusSort)) {
                return 1;
            }
            return 0;
        };

        return ndaStatusSort ? filteredProjects.sort(sortByTitle).sort(sortByNda) : filteredProjects.sort(sortByTitle)

    };

    filterCategory = (project, selectedProjectsCategory) => {
        return selectedProjectsCategory && typeof selectedProjectsCategory === 'object' ? project.projectsCategory && project.projectsCategory.value === selectedProjectsCategory.value : true
    };

    filterProjectSkills = (project, selectedSkills) => {
        return selectedSkills.length > 0 ? selectedSkills.every(skill => {return project.skills && project.skills.findIndex(projectsSkill => projectsSkill.value === skill.value) > -1}) : true
    };

    filterProjectTypes = (project, selectedProjectsTypes) => {
        return selectedProjectsTypes.length > 0 ? selectedProjectsTypes.every(projectsType => {return project.projectsTypes && project.projectsTypes.findIndex(type => type.value === projectsType.value) > -1}) : true
    };

    filterProjectStaff = (project, selectedStaff) => {
        return selectedStaff.length > 0 ? selectedStaff.every(employee => {return project.staff && project.staff.findIndex(projectsEmployee => projectsEmployee.value === employee.value) > -1}) : true
    };

    clearFilter = () => {
        this.setState({
            search: '',
            projectsCategory: '',
            selectedSkills: [],
            selectedProjectsTypes: [],
            selectedStaff: []
        });
    };

    render() {
        const {
            loading,
            projects,
            skills,
            selectedSkills,
            projectsCategories,
            projectsCategory,
            projectsTypes,
            selectedProjectsTypes,
            staff,
            selectedStaff,
            search
        } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-3 col-12 mb-4 mb-md-0 d-flex align-items-center">
                                <button className="btn btn-danger" onClick={this.clearFilter}>Clear projects filter</button>
                            </div>
                            <div className="col-md-6 col-12">
                                <h1 className="text-center mb-0">Evne projects</h1>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="offset-md-3 col-md-4 col-12 d-flex justify-content-between">
                                <button className="btn btn-light" onClick={this.ndaStatusSortChange} value="">No NDA sort</button>
                                <button className="btn btn-danger" onClick={this.ndaStatusSortChange} value="nda">NDA</button>
                                <button className="btn btn-primary" onClick={this.ndaStatusSortChange} value="ndaPlus">NDA+</button>
                                <button className="btn btn-success" onClick={this.ndaStatusSortChange} value="noNda">***</button>
                            </div>
                        </div>
                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-md-3 col-12 mb-4 mb-md-0">
                                <div className="form-group">
                                    <span className="d-block mb-2">Search project title</span>
                                    <input
                                        className="form-control"
                                        name="search"
                                        value={search}
                                        onChange={this.onChange}
                                        type="text"
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="d-block mb-2">Select project category</span>
                                    <Select
                                        options={projectsCategories}
                                        value={projectsCategory}
                                        onChange={value => this.setState({ projectsCategory: value })}
                                        placeholder="Select category"
                                        closeMenuOnSelect={true}
                                        className="mb-3"
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="d-block mb-2">Select project skills</span>
                                    <Select
                                        options={skills}
                                        value={selectedSkills}
                                        onChange={value => this.setState({ selectedSkills: value })}
                                        placeholder="Select skills"
                                        closeMenuOnSelect={false}
                                        className="mb-3"
                                        isMulti
                                        name="skills"
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="d-block mb-2">Select project type(s)</span>
                                    <Select
                                        options={projectsTypes}
                                        value={selectedProjectsTypes}
                                        onChange={value => this.setState({ selectedProjectsTypes: value })}
                                        placeholder="Select types"
                                        closeMenuOnSelect={false}
                                        className="mb-3"
                                        isMulti
                                    />
                                </div>
                                <div className="form-group">
                                    <span className="d-block mb-2">Select project staff</span>
                                    <Select
                                        options={staff}
                                        value={selectedStaff}
                                        onChange={value => this.setState({ selectedStaff: value })}
                                        placeholder="Select staff"
                                        closeMenuOnSelect={false}
                                        className="mb-3"
                                        isMulti
                                    />
                                </div>
                            </div>
                            <div className="col-md-9 col-12">
                                <ProjectsList projects={this.filterProjects(projects)}/>
                            </div>
                        </div>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const ProjectsList = ({ projects }) => (
    <table className="table">
        <thead className="thead-dark">
        <tr>
            <th>Project title</th>
        </tr>
        </thead>
        <tbody>
        {projects.map(project => (
            <tr key={project.uid}>
                <td>
                    <Link to={'/admin/projects/' + project.uid + '/view'}>
                        {project.title} {project.projectNdaStatus && <span style={{backgroundColor: ndaStatusColors[project.projectNdaStatus.value], color: 'white'}}>{project.projectNdaStatus.label}</span>}
                    </Link>
                </td>
            </tr>
        ))}
        </tbody>
    </table>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(EvneProjectsPage);