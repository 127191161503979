import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";
import {sortByName} from "../../utils";

class ProjectsTypesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            projectsTypes: [],
            search: '',
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.projectsTypes().on('value', snapshot => {
            const projectsTypesObject = snapshot.val() ? snapshot.val() : [];

            const projectsTypesList = Object.keys(projectsTypesObject).map(key => ({
                ...projectsTypesObject[key],
                uid: key,
            }));

            projectsTypesList.sort(sortByName);

            this.setState({
                projectsTypes: projectsTypesList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.projectsTypes().off();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    deleteProjectsType = (uid) => {
        this.props.firebase
            .projectsType(uid).remove()
            .then(() => {

            })
            .catch(error => {
                this.setState({ error });
            });
    };

    render() {
        const { projectsTypes, search, loading } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <div className="row no-gutters align-items-center justify-content-between">
                                    <h1>Projects Types</h1>
                                </div>
                                <div className="row no-gutters align-items-center justify-content-between">
                                    <div className="form-group mb-3 mb-md-0">
                                        <input
                                            className="form-control"
                                            name="search"
                                            value={search}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Search projects type"
                                        />
                                    </div>
                                    <Link to={ROUTES.PROJECTS_TYPES_ADD}><button className="btn btn-outline-primary">Add projects type</button></Link>
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ProjectsTypesList projectsTypes={projectsTypes.filter(projectsType => projectsType.name && projectsType.name.toLowerCase().includes(search.toLowerCase()))} deleteProjectsType={this.deleteProjectsType}/>
                            </div>
                        </div>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const ProjectsTypesList = ({ projectsTypes, deleteProjectsType }) => (
    <table className="table">
        <thead className="thead-dark" >
        <tr>
            <th>Project types name</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {
            projectsTypes.length > 0 ?
                (projectsTypes.map(projectsType => (
                    <tr key={projectsType.uid}>
                        <td><Link to={'/admin/projects-types/' + projectsType.uid}>{projectsType.name}</Link></td>
                        <td><button className="btn btn-danger" onClick={() => deleteProjectsType(projectsType.uid)}>Delete</button></td>
                    </tr>
                    ))
                ) :
                (
                    <tr>
                        <td className="text-center py-4" colSpan="2">Projects types list is empty</td>
                    </tr>
                )
        }
        </tbody>
    </table>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsTypesPage);