import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByName, sortBySkillLabel, sortByLabel} from "../../utils";

import Select from 'react-select'

const StaffEditPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Employee edit</h1>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <EmployeeEditForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    categoriesList: [],
    categoriesById: {},
    skill: '',
    skillPoint: '',
    skillPoints: [
        {label: 1, value: 1},
        {label: 2, value: 2},
        {label: 3, value: 3},
        {label: 4, value: 4},
        {label: 5, value: 5},
    ],
    skills: [],
    skillsList: [],
    cvLink: '',
    certLink: '',
    certLinks: [],
    cvLinkSet: false,
    error: null
};

class EmployeeEditFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });

        let uid = this.props.match.params.uid;

        this.props.firebase.employee(uid).on('value', snapshot => {
            const employee = snapshot.val();

            this.setState({
                firstName: employee.firstName,
                lastName: employee.lastName,
                cvLink: (employee.cvLink) ? employee.cvLink : '',
                cvLinkSet: employee.cvLink && employee.cvLink.length > 0,
                certLinks: (employee.certLinks) ? employee.certLinks : [],
                skills: (employee.skills) ? employee.skills : [],
                // loading: false,
            });
        });

        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val();

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                ...categoriesObject[key],
                uid: key,
            }));

            categoriesList.sort(sortByName);

            let categoriesById = {};

            Object.keys(categoriesObject).forEach((key) => {
                categoriesById[key] = categoriesObject[key]['name']
            });

            this.setState({
                categoriesList: categoriesList,
                categoriesById: categoriesById
            });
        });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val();

            const disabledSkills = this.state.skills.map((skill) => skill.skill.value);

            const skillsList = Object.keys(skillsObject).map(key => ({
                category: skillsObject[key].category,
                disabled: disabledSkills.indexOf(key) > -1,
                label: skillsObject[key].name,
                value: key,
            }));

            skillsList.sort(sortByLabel);

            this.setState({
                skillsList: skillsList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.employee(this.props.match.params.uid).off();
        this.props.firebase.skills().off();
        this.props.firebase.categories().off();
    }

    onSubmit = event => {
        const { firstName, lastName, skills, cvLink, certLinks } = this.state;

        let uid = this.props.match.params.uid;

        this.props.firebase
            .employee(uid).update({
                "firstName": firstName,
                "lastName": lastName,
                "skills": skills,
                "cvLink": cvLink,
                "certLinks": certLinks,
            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.STAFF);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSkillSelectChange = selectedSkill => {
        this.setState({skill: selectedSkill});
    };

    onPointSelectChange = selectedPoint => {
        this.setState({skillPoint: selectedPoint});
    };

    addCertLink = () => {
        this.setState({certLinks: [...this.state.certLinks, this.state.certLink]});
        this.setState({certLink: ''});
    };

    removeCertLink = (link) => {
        let a = this.state.certLinks;
        let index = a.indexOf(link);
        a.splice(index, 1);
        this.setState({certLinks: a});
    };

    addCvLink = () => {
        this.setState({cvLinkSet: true})
    };

    editCvLink = () => {
        this.setState({cvLinkSet: false})
    };

    addSkill = () => {
        this.setState({skills: [...this.state.skills, {'skill': this.state.skill, 'point': this.state.skillPoint}]});
        let a = this.state.skillsList.slice();
        let index = a.indexOf(this.state.skill);
        a[index]['disabled'] = true;
        this.setState({
            skillsList: a,
            skill: '',
            skillPoint: ''
        });
    };

    removeSkill = (skill) => {
        let skills = this.state.skills;
        let index = skills.indexOf(skill);
        skills.splice(index, 1);

        let skillsList = this.state.skillsList.slice();
        index = skillsList.findIndex(option => option.value === skill['skill'].value);
        skillsList[index]['disabled'] = false;
        this.setState({
            skills: skills,
            skillsList: skillsList
        });
    };

    render() {
        const {
            firstName,
            lastName,
            categoriesList,
            categoriesById,
            skill,
            skills,
            skillPoint,
            skillPoints,
            skillsList,
            cvLink,
            certLink,
            certLinks,
            cvLinkSet,
            error,
        } = this.state;

        const isInvalid =
            firstName === '' || lastName === '';

        const invalidCertLink = certLink.length === 0;

        const invalidSkill = skill.length === 0 || skillPoint.length === 0 ;

        const certLinksList = certLinks.map((link, index) =>
            <li key={index} className="pt-3">
                <div className="d-flex align-items-center justify-content-between">
                    <span><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></span>
                    <button className="btn btn-danger" type="button" onClick={() => this.removeCertLink(link)}>
                        Remove
                    </button>
                </div>
            </li>
        );

        let skillsByCategory = {};

        categoriesList.forEach(category => {
            skillsByCategory[category.uid] = skills.filter(skill => {return skill['skill']['category'] === category.uid}).sort(sortBySkillLabel)
        });

        const staffSkillList = Object.keys(skillsByCategory).map((key, index) =>
            skillsByCategory[key].length > 0 &&
            <div className="col-12 col-md-6 col-lg-6" key={index}>
                <span>{categoriesById[key]}</span>
                <ul>
                    {
                        skillsByCategory[key].map((obj, index) =>
                            <li key={index} className="skill mt-2">{obj['skill']['label']} - {obj['point']['label']}<button type="button" className="btn btn-danger ml-3 btn-remove-skill" onClick={() => this.removeSkill(obj, index)}>X</button></li>
                        )
                    }
                </ul>
            </div>
        );

        return (
            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="firstName"
                        value={firstName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="First name"
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="lastName"
                        value={lastName}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Last name"
                    />
                </div>
                {!cvLinkSet ? (
                    <div className="form-group d-flex">
                        <input
                            className="form-control"
                            name="cvLink"
                            value={cvLink}
                            onChange={this.onChange}
                            type="url"
                            placeholder="Please set CV link"
                        />
                        <button className="btn btn-primary" type="button" onClick={this.addCvLink}>
                            Set
                        </button>
                    </div>
                ) : (
                    <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>CV link: <a href={cvLink} target="_blank" rel="noopener noreferrer">{cvLink}</a></span>
                            <button className="btn btn-warning" type="button" onClick={this.editCvLink}>
                                Edit
                            </button>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="certLink">Certificates links</label>
                    <div className="d-flex">
                        <input
                            id="certLink"
                            className="form-control"
                            name="certLink"
                            value={certLink}
                            onChange={this.onChange}
                            type="url"
                        />
                        <button className="btn btn-primary" type="button" disabled={invalidCertLink} onClick={this.addCertLink}>
                            Add
                        </button>
                    </div>
                    <div>
                        <ul>{certLinksList}</ul>
                    </div>
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Staff skills</span>
                    <Select
                        options={skillsList}
                        value={skill}
                        onChange={this.onSkillSelectChange}
                        placeholder="Select skill"
                        closeMenuOnSelect={true}
                        className="mb-3"
                        isOptionDisabled={(option) => option.disabled}
                    />
                    <Select
                        options={skillPoints}
                        value={skillPoint}
                        onChange={this.onPointSelectChange}
                        placeholder="Select skill point"
                        closeMenuOnSelect={true}
                        className="mb-3"
                    />
                    <button className="btn btn-primary" type="button" disabled={invalidSkill} onClick={this.addSkill}>
                        Add
                    </button>
                </div>
                <div className="row">
                    {staffSkillList}
                </div>
                <button className="btn btn-dark" disabled={isInvalid} type="button" onClick={() => {this.props.history.goBack()}}>
                    Back
                </button>
                <button className="btn btn-success float-right" disabled={isInvalid} type="submit">
                    Save
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const EmployeeEditForm = compose(
    withRouter,
    withFirebase,
)(EmployeeEditFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(StaffEditPage);

// export default CategoryAddPage;

// export { CategoryAddForm };