import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import Navigation from '../Navigation';
import LoginPage from '../Login';
// import SignUpPage from '../SignUp';
// import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
// import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
// Staff pages
import EvneStaffPage from '../EvneStaff'
import StaffPage from '../Staff';
import StaffAddPage from '../StaffAdd';
import StaffEditPage from '../StaffEdit';
import StaffViewPage from '../StaffView';
// Category pages
import CategoryPage from '../Category';
import CategoryAddPage from '../CategoryAdd';
import CategoryEditPage from '../CategoryEdit';
// Skills pages
import SkillsPage from '../Skills';
import SkillsAddPage from '../SkillsAdd';
import SkillsEditPage from '../SkillsEdit';
// Projects pages
import EvneProjectsPage from '../EvneProjects'
import ProjectsPage from '../Projects';
import ProjectsAddPage from '../ProjectsAdd';
import ProjectsEditPage from '../ProjectsEdit';
import ProjectsViewPage from '../ProjectsView';
// Projects types pages
import ProjectsTypesPage from '../ProjectsTypes';
import ProjectsTypesAddPage from '../ProjectsTypesAdd';
import ProjectsTypesEditPage from '../ProjectsTypesEdit';
// Projects categories pages
import ProjectsCategoriesPage from '../ProjectsCategories';
import ProjectsCategoriesAddPage from '../ProjectsCategoriesAdd';
import ProjectsCategoriesEditPage from '../ProjectsCategoriesEdit';


import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

const App = () => (
    <Router>
        <div>
            <div className="container">
                <Navigation />
            </div>
            <hr />
            <Switch>
                <Route exact path={ROUTES.LOGIN} component={LoginPage} />
                <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                <Route exact path={ROUTES.ADMIN} component={AdminPage} />

                <Route path={ROUTES.EVNE_STAFF} component={EvneStaffPage} />
                <Route exact path={ROUTES.STAFF_ADD} component={StaffAddPage} />
                <Route exact path={ROUTES.STAFF_VIEW} component={StaffViewPage} />
                <Route exact path={ROUTES.STAFF_EDIT} component={StaffEditPage} />
                <Route exact path={ROUTES.STAFF} component={StaffPage} />

                <Route exact path={ROUTES.CATEGORY_ADD} component={CategoryAddPage} />
                <Route exact path={ROUTES.CATEGORY_EDIT} component={CategoryEditPage} />
                <Route exact path={ROUTES.CATEGORY} component={CategoryPage} />

                <Route exact path={ROUTES.SKILLS_ADD} component={SkillsAddPage} />
                <Route exact path={ROUTES.SKILLS_EDIT} component={SkillsEditPage} />
                <Route exact path={ROUTES.SKILLS} component={SkillsPage} />

                <Route exact path={ROUTES.EVNE_PROJECTS} component={EvneProjectsPage} />
                <Route path={ROUTES.PROJECTS_ADD} component={ProjectsAddPage} />
                <Route exact path={ROUTES.PROJECTS_VIEW} component={ProjectsViewPage} />
                <Route exact path={ROUTES.PROJECTS_EDIT} component={ProjectsEditPage} />
                <Route exact path={ROUTES.PROJECTS} component={ProjectsPage} />

                <Route exact path={ROUTES.PROJECTS_TYPES_ADD} component={ProjectsTypesAddPage} />
                <Route exact path={ROUTES.PROJECTS_TYPES_EDIT} component={ProjectsTypesEditPage} />
                <Route exact path={ROUTES.PROJECTS_TYPES} component={ProjectsTypesPage} />

                <Route exact path={ROUTES.PROJECTS_CATEGORIES_ADD} component={ProjectsCategoriesAddPage} />
                <Route exact path={ROUTES.PROJECTS_CATEGORIES_EDIT} component={ProjectsCategoriesEditPage} />
                <Route exact path={ROUTES.PROJECTS_CATEGORIES} component={ProjectsCategoriesPage} />
                <Redirect to={ROUTES.EVNE_STAFF} />
            </Switch>
        </div>
    </Router>
);

export default withAuthentication(App);