export const sortByName = (a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const sortByTitle = (a, b) => {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const sortByLabel = (a, b) => {
    if (a['label'].toLowerCase() < b['label'].toLowerCase()) {
        return -1;
    }
    if (a['label'].toLowerCase() > b['label'].toLowerCase()) {
        return 1;
    }
    return 0;
};

export const sortBySkillLabel = (a, b) => {
    if (a['skill']['label'].toLowerCase() < b['skill']['label'].toLowerCase()) {
        return -1;
    }
    if (a['skill']['label'].toLowerCase() > b['skill']['label'].toLowerCase()) {
        return 1;
    }
    return 0;
};

export const employeeSort = (a, b) => {
    if ([a.firstName, a.lastName].join(' ') < [b.firstName, b.lastName].join(' ')) {
        return -1;
    }
    if ([a.firstName, a.lastName].join(' ') > [b.firstName, b.lastName].join(' ')) {
        return 1;
    }
    return 0;
};

export const sortStaffByLabel = (a, b) => {
    let arrayA = a['label'].split(' ');
    a = [arrayA[1], arrayA[0]].join('');
    let arrayB = b['label'].split(' ');
    b = [arrayB[1], arrayB[0]].join('');

    if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
    }
    return 0;
};