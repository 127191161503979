import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByName, sortBySkillLabel} from "../../utils";

const StaffViewPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Employee view</h1>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <EmployeeView />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    categoriesList: [],
    categoriesById: {},
    skills: [],
    cvLink: '',
    certLinks: [],
};

class EmployeeViewBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });

        let uid = this.props.match.params.uid;

        this.props.firebase.employee(uid).on('value', snapshot => {
            const employee = snapshot.val();

            this.setState({
                firstName: employee.firstName,
                lastName: employee.lastName,
                cvLink: (employee.cvLink) ? employee.cvLink : '',
                cvLinkSet: employee.cvLink && employee.cvLink.length > 0,
                certLinks: (employee.certLinks) ? employee.certLinks : [],
                skills: (employee.skills) ? employee.skills : [],
                // loading: false,
            });
        });

        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val();

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                ...categoriesObject[key],
                uid: key,
            }));

            categoriesList.sort(sortByName);

            let categoriesById = {};

            Object.keys(categoriesObject).forEach((key) => {
                categoriesById[key] = categoriesObject[key]['name']
            });

            this.setState({
                categoriesList: categoriesList,
                categoriesById: categoriesById
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.employee(this.props.match.params.uid).off();
        this.props.firebase.categories().off();
    }

    render() {
        const {
            firstName,
            lastName,
            categoriesList,
            categoriesById,
            skills,
            cvLink,
            certLinks,
        } = this.state;

        const certLinksList = certLinks.map((link, index) =>
            <li key={index} className={index !== 0 ? "pt-3" : ""}>
                <div className="d-flex align-items-center justify-content-between">
                    <span><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></span>
                </div>
            </li>
        );

        let skillsByCategory = {};

        categoriesList.forEach(category => {
            skillsByCategory[category.uid] = skills.filter(skill => {return skill['skill']['category'] === category.uid}).sort(sortBySkillLabel)
        });

        const staffSkillList = Object.keys(skillsByCategory).map((key, index) =>
            skillsByCategory[key].length > 0 &&
            <div key={index}>
                <span>{categoriesById[key]}</span>
                <ul>
                    {
                        skillsByCategory[key].map((obj, index) =>
                            localStorage.getItem('email') === 'peter@parker.com' ? (<li key={index} className="mt-2">{obj['skill']['label']} - {obj['point']['label']}</li>) : (<li key={index} className="mt-2">{obj['skill']['label']}</li>)
                        )
                    }
                </ul>
            </div>
        );

        return (
            <div>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>First name</td>
                            <td>{firstName}</td>
                        </tr>
                        <tr>
                            <td>Last name</td>
                            <td>{lastName}</td>
                        </tr>
                        <tr>
                            <td>CV link</td>
                            <td>{cvLink.length > 0 ? <a href={cvLink} target="_blank" rel="noopener noreferrer">{cvLink}</a> : 'Not set'}</td>
                        </tr>
                        <tr>
                            <td>Certificates links</td>
                            <td>
                                <ul>{certLinksList}</ul>
                            </td>
                        </tr>
                        <tr>
                            <td>Staff skills</td>
                            <td>{staffSkillList}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-dark" type="button" onClick={() => {this.props.history.goBack()}}>Back</button>
                    {
                        localStorage.getItem('email') === 'peter@parker.com' &&
                        <Link to={'/admin/staff/' + this.props.match.params.uid}>
                            <button className="btn btn-outline-info" type="button">Edit</button>
                        </Link>
                    }
                </div>
            </div>
        );
    }
}

const EmployeeView = compose(
    withRouter,
    withFirebase,
)(EmployeeViewBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(StaffViewPage);

// export default CategoryAddPage;

// export { CategoryAddForm };