import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";

const CategoryAddPage = (props) => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Category add</h1>
                <h5>You can add multiple categories, simply divides them by comma</h5>
                <button className="btn btn-dark my-4" type="button" onClick={() => {props.history.goBack()}}>
                    Back
                </button>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <CategoryAddForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    name: '',
    error: null,
};

class CategoryAddFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        this.addCategory(event, false);
    };

    onSubmitAndContinue = event => {
        this.addCategory(event, true);
    };

    addCategory = (event, _continue) => {
        const { name } = this.state;

        let categoryName = name.split(',');

        categoryName.map(category => {return category.trim()});

        categoryName.forEach((name, index) => {
            let key = this.props.firebase.categories().push().key;

            this.props.firebase
                .category(key).set({"name": name})
                .then(() => {
                    this.setState({ ...INITIAL_STATE });
                    if (!_continue && (index + 1 === categoryName.length )) {
                        this.props.history.push(ROUTES.CATEGORY);
                    }
                })
                .catch(error => {
                    this.setState({ error });
                });
        });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            name,
            error,
        } = this.state;

        const isInvalid =
            name === '';


        return (
            <form>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Category Name"
                    />
                </div>
                <button className="btn btn-success" disabled={isInvalid} type="submit" onClick={this.onSubmit}>
                    Save
                </button>

                <button className="btn btn-primary float-right" disabled={isInvalid} type="submit" onClick={this.onSubmitAndContinue}>
                    Save and add another
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const CategoryAddForm = compose(
    withRouter,
    withFirebase,
)(CategoryAddFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CategoryAddPage);

// export default CategoryAddPage;

// export { CategoryAddForm };