import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByLabel, sortStaffByLabel} from "../../utils";

const ProjectsViewPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Project view</h1>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <ProjectView />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    title: '',
    projectNdaStatus: '',
    description: '',
    url: '',
    selectedSkills:[],
    projectsCategories: [],
    projectsCategory: '',
    selectedProjectsTypes: [],
    projectPresentation: '',
    selectedStaff: [],
    projectManager: '',
    designEffort: '',
    developmentEffort: '',
};

class ProjectViewBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });
        this.getData();
    }

    componentWillUnmount() {
        this.props.firebase.project(this.props.match.params.uid).off();
    }

    getData = () => {

        let uid = this.props.match.params.uid;

        this.props.firebase.project(uid).on('value', snapshot => {
            const project = snapshot.val();

            this.setState({
                title: project.title,
                projectNdaStatus: (project.projectNdaStatus) ? project.projectNdaStatus : '',
                description: (project.description) ? project.description : '',
                url: (project.url) ? project.url : '',
                selectedSkills: (project.skills) ? project.skills.sort(sortByLabel) : [],
                projectsCategory: (project.projectsCategory) ? project.projectsCategory : '',
                selectedProjectsTypes: (project.projectsTypes) ? project.projectsTypes.sort(sortByLabel) : [],
                projectPresentation: (project.projectPresentation) ? project.projectPresentation : '',
                selectedStaff: (project.staff) ? project.staff.sort(sortStaffByLabel) : [],
                projectManager: (project.projectManager) ? project.projectManager : '',
                designEffort: (project.designEffort) ? project.designEffort : '',
                developmentEffort: (project.developmentEffort) ? project.developmentEffort : '',
            });
        });
    };

    render() {
        const {
            title,
            projectNdaStatus,
            description,
            url,
            selectedSkills,
            projectsCategory,
            selectedProjectsTypes,
            projectPresentation,
            selectedStaff,
            projectManager,
            designEffort,
            developmentEffort,
        } = this.state;

        const projectSkillList = selectedSkills.map((skill, index) =>
            <li key={index} className={index !== 0 ? "pt-3" : ""}>
                {skill.label}
            </li>
        );

        const projectTypesList = selectedProjectsTypes.map((projectsType, index) =>
            <li key={index} className={index !== 0 ? "pt-3" : ""}>
                {projectsType.label}
            </li>
        );

        const projectStaffList = selectedStaff.map((employee, index) =>
            <li key={index} className={index !== 0 ? "pt-3" : ""}>
                {employee.label}
            </li>
        );

        return (
            <div className="my-4">
                <table className="table">
                    <tbody>
                    <tr>
                        <td>Project title</td>
                        <td>{title}</td>
                    </tr>
                    <tr>
                        <td>Project NDA status</td>
                        <td>{projectNdaStatus.label}</td>
                    </tr>
                    <tr>
                        <td>Project description</td>
                        <td>{description}</td>
                    </tr>
                    <tr>
                        <td>Project url</td>
                        <td>{url.length > 0 ? <a href={url} target="_blank" rel="noopener noreferrer">{url}</a> : 'Not set'}</td>
                    </tr>
                    <tr>
                        <td>Project skills</td>
                        <td>{projectSkillList}</td>
                    </tr>
                    <tr>
                        <td>Project category</td>
                        <td>{projectsCategory.label}</td>
                    </tr>
                    <tr>
                        <td>Project types</td>
                        <td>{projectTypesList}</td>
                    </tr>
                    <tr>
                        <td>Project presentation url</td>
                        <td>{projectPresentation.length > 0 ? <a href={projectPresentation} target="_blank" rel="noopener noreferrer">{projectPresentation}</a> : 'Not set'}</td>
                    </tr>
                    <tr>
                        <td>Project staff</td>
                        <td>{projectStaffList}</td>
                    </tr>
                    <tr>
                        <td>Project manager</td>
                        <td>{projectManager}</td>
                    </tr>
                    <tr>
                        <td>Design man-hours</td>
                        <td>{designEffort}</td>
                    </tr>
                    <tr>
                        <td>Development man-hours</td>
                        <td>{developmentEffort}</td>
                    </tr>
                    </tbody>
                </table>
                <div className="d-flex justify-content-between">
                    <button className="btn btn-dark" type="button" onClick={() => {this.props.history.goBack()}}>Back</button>
                    {
                        localStorage.getItem('email') === 'peter@parker.com' &&
                        <Link to={'/admin/projects/' + this.props.match.params.uid}>
                            <button className="btn btn-outline-info" type="button">Edit</button>
                        </Link>
                    }
                </div>
            </div>
        );
    }
}

const ProjectView = compose(
    withRouter,
    withFirebase,
)(ProjectViewBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsViewPage);

// export default CategoryAddPage;

// export { CategoryAddForm };