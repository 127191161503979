export const ndaStatus = [
    { value: 'nda', label: 'NDA' },
    { value: 'ndaPlus', label: 'NDA(+)' },
    { value: 'noNda', label: '***' },
];

export const ndaStatusColors = {
    'nda': 'red',
    'ndaPlus': 'blue',
    'noNda': 'green'
};