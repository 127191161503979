import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByLabel, sortStaffByLabel} from "../../utils";
import {ndaStatus} from "../../constants";

import Select from 'react-select'

const ProjectsEditPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Project edit</h1>
                <div className="row my-3">
                    <div className="col-12 col-md-6">
                        <ProjectEditForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    title: '',
    projectNdaStatus: '',
    description: '',
    url: '',
    urlSet: false,
    skills: [],
    selectedSkills:[],
    projectsCategories: [],
    projectsCategory: '',
    projectsTypes: [],
    selectedProjectsTypes: [],
    projectPresentation: '',
    projectPresentationSet: false,
    staff: [],
    selectedStaff: [],
    projectManager: '',
    designEffort: '',
    developmentEffort: '',
    error: null,
};

class ProjectEditFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });
        this.getData();
    }

    componentWillUnmount() {
        this.props.firebase.project(this.props.match.params.uid).off();
        this.props.firebase.skills().off();
        this.props.firebase.projectsTypes().off();
        this.props.firebase.projectsCategories().off();
        this.props.firebase.staff().off();
    }

    getData = () => {

        let uid = this.props.match.params.uid;

        this.props.firebase.project(uid).on('value', snapshot => {
            const project = snapshot.val();

            this.setState({
                title: project.title,
                projectNdaStatus: (project.projectNdaStatus) ? project.projectNdaStatus : '',
                description: (project.description) ? project.description : '',
                url: (project.url) ? project.url : '',
                urlSet: project.url && project.url.length > 0,
                selectedSkills: (project.skills) ? project.skills : [],
                projectsCategory: (project.projectsCategory) ? project.projectsCategory : '',
                selectedProjectsTypes: (project.projectsTypes) ? project.projectsTypes : [],
                projectPresentation: (project.projectPresentation) ? project.projectPresentation : '',
                projectPresentationSet: project.projectPresentation && project.projectPresentation.length > 0,
                selectedStaff: (project.staff) ? project.staff : [],
                projectManager: (project.projectManager) ? project.projectManager : '',
                designEffort: (project.designEffort) ? project.designEffort : '',
                developmentEffort: (project.developmentEffort) ? project.developmentEffort : '',
            });
        });

        this.props.firebase.projectsCategories().on('value', snapshot => {
            const projectsCategoriesObject = snapshot.val() ? snapshot.val() : [];

            const projectsCategories = Object.keys(projectsCategoriesObject).map(key => ({
                label: projectsCategoriesObject[key].name,
                value: key,
            }));

            projectsCategories.sort(sortByLabel);

            this.setState({
                projectsCategories: projectsCategories,
            });
        });

        this.props.firebase.projectsTypes().on('value', snapshot => {
            const projectsTypesObject = snapshot.val() ? snapshot.val() : [];

            const projectsTypes = Object.keys(projectsTypesObject).map(key => ({
                label: projectsTypesObject[key].name,
                value: key,
            }));

            projectsTypes.sort(sortByLabel);

            this.setState({
                projectsTypes: projectsTypes,
            });
        });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val() ? snapshot.val() : [];

            const skills = Object.keys(skillsObject).map(key => ({
                label: skillsObject[key].name,
                value: key,
            }));

            skills.sort(sortByLabel);

            this.setState({
                skills: skills,
            });
        });

        this.props.firebase.staff().on('value', snapshot => {
            const staffObject = snapshot.val() ? snapshot.val() : [];

            const staff = Object.keys(staffObject).map(key => ({
                label: [staffObject[key].firstName, staffObject[key].lastName].join(' '),
                value: key,
            }));

            staff.sort(sortStaffByLabel);

            this.setState({
                staff: staff,
                loading: false,
            });
        });
    };

    onSubmit = event => {
        const { title, projectNdaStatus, description, url, selectedSkills, projectsCategory, selectedProjectsTypes, projectPresentation, selectedStaff, projectManager, designEffort, developmentEffort } = this.state;

        let uid = this.props.match.params.uid;

        this.props.firebase
            .project(uid).update({
            "title": title,
            "projectNdaStatus": projectNdaStatus,
            "description": description,
            "url": url,
            "skills": selectedSkills,
            "projectsCategory": projectsCategory,
            "projectsTypes": selectedProjectsTypes,
            "projectPresentation": projectPresentation,
            "staff": selectedStaff,
            "projectManager": projectManager,
            "designEffort": designEffort,
            "developmentEffort": developmentEffort,
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.PROJECTS);

        }).catch(error => {
            this.setState({ error });
        });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            title,
            projectNdaStatus,
            description,
            url,
            urlSet,
            skills,
            selectedSkills,
            projectsCategories,
            projectsCategory,
            projectsTypes,
            selectedProjectsTypes,
            projectPresentation,
            projectPresentationSet,
            staff,
            selectedStaff,
            projectManager,
            designEffort,
            developmentEffort,
            error,
        } = this.state;

        const isInvalid = title === '';

        return (
            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <label htmlFor="title">Project title</label>
                    <input
                        id="title"
                        className="form-control"
                        name="title"
                        value={title}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">NDA status</span>
                    <Select
                        options={ndaStatus}
                        value={projectNdaStatus}
                        onChange={value => this.setState({ projectNdaStatus: value })}
                        placeholder="Select NDA status"
                        closeMenuOnSelect={true}
                        className="mb-3"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Project description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        rows="3"
                        name="description"
                        value={description}
                        onChange={this.onChange}
                    >
                    </textarea>
                </div>
                {!urlSet ? (
                    <div className="form-group d-flex">
                        <input
                            className="form-control"
                            name="url"
                            value={url}
                            onChange={this.onChange}
                            type="url"
                            placeholder="Project url"
                        />
                        <button className="btn btn-primary" type="button" onClick={() => {this.setState({urlSet: true})}}>
                            Set
                        </button>
                    </div>
                ) : (
                    <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>Project url: <a href={url} target="_blank" rel="noopener noreferrer">{url}</a></span>
                            <button className="btn btn-warning" type="button" onClick={() => {this.setState({urlSet: false})}}>
                                Edit
                            </button>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <span className="d-block mb-2">Project skills</span>
                    <Select
                        options={skills}
                        value={selectedSkills}
                        onChange={value => this.setState({ selectedSkills: value })}
                        placeholder="Select skills"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                        name="skills"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project category</span>
                    <Select
                        options={projectsCategories}
                        value={projectsCategory}
                        onChange={value => this.setState({ projectsCategory: value })}
                        placeholder="Select category"
                        closeMenuOnSelect={true}
                        className="mb-3"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project type(s)</span>
                    <Select
                        options={projectsTypes}
                        value={selectedProjectsTypes}
                        onChange={value => this.setState({ selectedProjectsTypes: value })}
                        placeholder="Select types"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                    />
                </div>
                {!projectPresentationSet ? (
                    <div className="form-group d-flex">
                        <input
                            className="form-control"
                            name="projectPresentation"
                            value={projectPresentation}
                            onChange={this.onChange}
                            type="url"
                            placeholder="Project presentation"
                        />
                        <button className="btn btn-primary" type="button" onClick={() => {this.setState({projectPresentationSet: true})}}>
                            Set
                        </button>
                    </div>
                ) : (
                    <div className="form-group">
                        <div className="d-flex align-items-center justify-content-between">
                            <span>Project presentation url: <a href={projectPresentation} target="_blank" rel="noopener noreferrer">{projectPresentation}</a></span>
                            <button className="btn btn-warning" type="button" onClick={() => {this.setState({projectPresentationSet: false})}}>
                                Edit
                            </button>
                        </div>
                    </div>
                )}
                <div className="form-group">
                    <span className="d-block mb-2">Project staff</span>
                    <Select
                        options={staff}
                        value={selectedStaff}
                        onChange={value => this.setState({ selectedStaff: value })}
                        placeholder="Select staff"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project manager</span>
                    <input
                        className="form-control"
                        name="projectManager"
                        value={projectManager}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Design man-hours:</span>
                    <input
                        className="form-control"
                        name="designEffort"
                        value={designEffort}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Development man-hours:</span>
                    <input
                        className="form-control"
                        name="developmentEffort"
                        value={developmentEffort}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <button className="btn btn-dark" type="button" onClick={() => {this.props.history.goBack()}}>
                    Back
                </button>
                <button className="btn btn-success float-right" disabled={isInvalid} type="submit">
                    Save
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const ProjectEditForm = compose(
    withRouter,
    withFirebase,
)(ProjectEditFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsEditPage);

// export default CategoryAddPage;

// export { CategoryAddForm };