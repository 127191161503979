import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";

const ProjectsCategoriesEditPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Projects category edit</h1>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <ProjectsCategoryEditForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    name: '',
    error: null,
    // loading: false,
};

class ProjectsCategoryEditFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });

        let uid = this.props.match.params.uid;

        this.props.firebase.projectsCategory(uid).on('value', snapshot => {
            const projectsCategory = snapshot.val();

            this.setState({
                name: projectsCategory.name,
                // loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.projectsCategory(this.props.match.params.uid).off();
    }

    onSubmit = event => {
        const { name } = this.state;

        let uid = this.props.match.params.uid;

        this.props.firebase
            .projectsCategory(uid).update({"name": name})
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.PROJECTS_CATEGORIES);

            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            name,
            error,
        } = this.state;

        const isInvalid =
            name === '';


        return (
            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Projects category name"
                    />
                </div>
                <button className="btn btn-dark" type="button" onClick={() => {this.props.history.goBack()}}>
                    Back
                </button>
                <button className="btn btn-success float-right" disabled={isInvalid} type="submit">
                    Save
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const ProjectsCategoryEditForm = compose(
    withRouter,
    withFirebase,
)(ProjectsCategoryEditFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsCategoriesEditPage);

// export default CategoryAddPage;

// export { CategoryAddForm };