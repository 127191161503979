import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByLabel} from "../../utils";

import Select from 'react-select'

const SkillsEditPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Skill edit</h1>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <SkillEditForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    name: '',
    category: '',
    categoriesList: [],
    error: null,
    // loading: false,
};

class SkillEditFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });

        let uid = this.props.match.params.uid;

        this.props.firebase.skill(uid).on('value', snapshot => {
            const skill = snapshot.val();

            this.setState({
                name: skill.name,
                category: skill.category
                // loading: false,
            });
        });

        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val();

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                label: categoriesObject[key].name,
                value: key,
            }));

            categoriesList.sort(sortByLabel);

            this.setState({
                categoriesList: categoriesList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.skill(this.props.match.params.uid).off();
        this.props.firebase.categories().off();
    }

    onSubmit = event => {
        const { name, category } = this.state;

        let uid = this.props.match.params.uid;

        this.props.firebase
            .skill(uid).update({"name": name, "category": category})
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.SKILLS);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSelectChange = selectedCategory => {
        this.setState({category: selectedCategory.value})
    };

    getValue = (categoriesList, category) => {
        let value = categoriesList.find((element, index, array) => {return element.value === category});
        return (value) ? value: null;
    };

    render() {
        const {
            name,
            category,
            categoriesList,
            error,
        } = this.state;

        const isInvalid =
            name === '' || category === '';


        return (
            <form onSubmit={this.onSubmit}>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Skill Name"
                    />
                </div>
                <div className="form-group">
                    <Select
                        options={categoriesList}
                        value={this.getValue(categoriesList, category)}
                        onChange={this.onSelectChange}
                        placeholder="Select category"
                    />
                </div>
                <button className="btn btn-dark" disabled={isInvalid} type="submit" onClick={() => {this.props.history.goBack()}}>
                    Back
                </button>
                <button className="btn btn-success float-right" disabled={isInvalid} type="submit">
                    Save
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SkillEditForm = compose(
    withRouter,
    withFirebase,
)(SkillEditFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(SkillsEditPage);

// export default CategoryAddPage;

// export { CategoryAddForm };