import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";
import {sortByTitle} from "../../utils";

class ProjectsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            projects: [],
            search: ''
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.projects().on('value', snapshot => {
            const projectsObject = snapshot.val() ? snapshot.val() : [];

            const projectsList = Object.keys(projectsObject).map(key => ({
                ...projectsObject[key],
                uid: key,
            }));

            projectsList.sort(sortByTitle);

            this.setState({
                projects: projectsList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.projects().off();
    }

    deleteProjects = (uid) => {
        this.props.firebase
            .project(uid).remove()
            .then(() => {

            })
            .catch(error => {
                this.setState({ error });
            });
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { projects, loading, search } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="row no-gutters align-items-center justify-content-between mb-5">
                                    <span className="h1 mb-3 mb-md-0">Projects</span>
                                    <div className="form-group mb-3 mb-md-0">
                                        <input
                                            className="form-control"
                                            name="search"
                                            value={search}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Search project"
                                        />
                                    </div>
                                    <Link to={ROUTES.PROJECTS_ADD}>
                                        <button className="btn btn-outline-primary">Add project</button>

                                    </Link>
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <ProjectsList projects={projects.filter(project => project.title && project.title.toLowerCase().includes(search.toLowerCase()))} deleteProjects={this.deleteProjects}/>
                            </div>
                        </div>

                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const ProjectsList = ({ projects, deleteProjects }) => (
    <table className="table">
        <thead className="thead-dark">
        <tr>
            <th>Project name</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {
            projects.length > 0 ? (
                projects.map(project => (
                    <tr key={project.uid}>
                        <td><Link to={'/admin/projects/' + project.uid}>{project.title}</Link></td>
                        <td><button className="btn btn-danger" onClick={() => deleteProjects(project.uid)}>Delete</button></td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td className="text-center py-4" colSpan="2">Projects list is empty</td>
                </tr>
            )
        }
        </tbody>
    </table>
);


const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsPage);