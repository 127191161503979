import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
    <form className="form-inline">
        <button className="btn btn-outline-danger" type="button" onClick={firebase.doSignOut}>
            Sign Out
        </button>
    </form>
);

export default withFirebase(SignOutButton);