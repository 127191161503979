import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByLabel} from "../../utils";

import Select from 'react-select'

const SkillsAddPage = (props) => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Skill add</h1>
                <h5>You can add multiple skills, simply divides them by comma(but be aware they will have same category for all)</h5>
                <button className="btn btn-dark my-4" type="button" onClick={() => {props.history.goBack()}}>
                    Back
                </button>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <SkillAddForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    name: '',
    category: '',
    categoriesList: [],
    error: null,
};

class SkillAddFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });
        this.getCategories();
    }

    componentWillUnmount() {
        this.props.firebase.categories().off();
    }

    getCategories = () => {
        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val();

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                label: categoriesObject[key].name,
                value: key,
            }));

            categoriesList.sort(sortByLabel);

            this.setState({
                categoriesList: categoriesList,
                loading: false,
            });
        });
    };

    onSubmit = event => {
        this.addSkill(event, false);
    };

    onSubmitAndContinue = event => {
        this.addSkill(event, true);
    };

    addSkill = (event, _continue) => {
        const { name, category } = this.state;

        let skillName = name.split(',');

        skillName.map(skill => {return skill.trim()});

        skillName.forEach((name, index) => {

            let key = this.props.firebase.skills().push().key;

            this.props.firebase
                .skill(key).set({
                "name": name,
                "category": category
            })
                .then(() => {
                    this.setState({ ...INITIAL_STATE });
                    if (!_continue && (index + 1 === skillName.length )) {
                        this.props.history.push(ROUTES.SKILLS);
                    } else {
                        this.getCategories();
                    }
                })
                .catch(error => {
                    this.setState({ error });
                });
        });


        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onSelectChange = selectedCategory => {
        this.setState({category: selectedCategory.value})
    };

    getValue = (categoriesList, category) => {
        let value = categoriesList.find((element, index, array) => {return element.value === category});
        return (value) ? value: null;
    };

    render() {
        const {
            name,
            category,
            categoriesList,
            error,
        } = this.state;

        const isInvalid =
            name === '' || category === '';


        return (
            <form>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Skill name"
                    />
                </div>
                <div className="form-group">
                    <Select
                        options={categoriesList}
                        value={this.getValue(categoriesList, category)}
                        onChange={this.onSelectChange}
                        placeholder="Select category"
                    />
                </div>
                <button className="btn btn-success" disabled={isInvalid} type="submit" onClick={this.onSubmit}>
                    Save
                </button>

                <button className="btn btn-primary float-right" disabled={isInvalid} type="submit" onClick={this.onSubmitAndContinue}>
                    Save and add another
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SkillAddForm = compose(
    withRouter,
    withFirebase,
)(SkillAddFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(SkillsAddPage);

// export default CategoryAddPage;

// export { CategoryAddForm };