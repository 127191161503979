export const LOGIN = '/login';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';

export const EVNE_STAFF = '/evne-staff';
export const STAFF = '/admin/staff';
export const STAFF_ADD = '/admin/staff/add';
export const STAFF_EDIT = '/admin/staff/:uid';
export const STAFF_VIEW = '/admin/staff/:uid/view';

export const CATEGORY = '/admin/category';
export const CATEGORY_ADD = '/admin/category/add';
export const CATEGORY_EDIT = '/admin/category/:uid';

export const SKILLS = '/admin/skills';
export const SKILLS_ADD = '/admin/skills/add';
export const SKILLS_EDIT = '/admin/skills/:uid';

export const EVNE_PROJECTS = '/evne-projects';
export const PROJECTS = '/admin/projects';
export const PROJECTS_ADD = '/admin/projects/add';
export const PROJECTS_EDIT = '/admin/projects/:uid';
export const PROJECTS_VIEW = '/admin/projects/:uid/view';

export const PROJECTS_TYPES = '/admin/projects-types';
export const PROJECTS_TYPES_ADD = '/admin/projects-types/add';
export const PROJECTS_TYPES_EDIT = '/admin/projects-types/:uid';

export const PROJECTS_CATEGORIES = '/admin/projects-categories';
export const PROJECTS_CATEGORIES_ADD = '/admin/projects-categories/add';
export const PROJECTS_CATEGORIES_EDIT = '/admin/projects-categories/:uid';