import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withAuthorization from "../Session/withAuthorization";
import AuthUserContext from "../Session/context";
import {sortByLabel, sortStaffByLabel} from "../../utils";
import {ndaStatus} from "../../constants";

import Select from 'react-select'

const ProjectsAddPage = (props) => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1>Project add</h1>
                <div className="row my-3">
                    <div className="col-12 col-md-6">
                        <button className="btn btn-dark my-4" type="button" onClick={() => {props.history.goBack()}}>
                            Back
                        </button>
                        <ProjectAddForm />
                    </div>
                </div>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    title: '',
    projectNdaStatus: '',
    description: '',
    url: '',
    skills: [],
    selectedSkills:[],
    projectsCategories: [],
    projectsCategory: '',
    projectsTypes: [],
    selectedProjectsTypes: [],
    projectPresentation: '',
    staff: [],
    selectedStaff: [],
    projectManager: '',
    designEffort: '',
    developmentEffort: '',
    error: null,
};

class ProjectAddFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        // this.setState({ loading: true });
        this.getData();
    }

    componentWillUnmount() {
        this.props.firebase.skills().off();
        this.props.firebase.projectsTypes().off();
        this.props.firebase.projectsCategories().off();
        this.props.firebase.staff().off();
    }

    getData = () => {

        this.props.firebase.projectsCategories().on('value', snapshot => {
            const projectsCategoriesObject = snapshot.val() ? snapshot.val() : [];

            const projectsCategories = Object.keys(projectsCategoriesObject).map(key => ({
                label: projectsCategoriesObject[key].name,
                value: key,
            }));

            projectsCategories.sort(sortByLabel);

            this.setState({
                projectsCategories: projectsCategories,
            });
        });

        this.props.firebase.projectsTypes().on('value', snapshot => {
            const projectsTypesObject = snapshot.val() ? snapshot.val() : [];

            const projectsTypes = Object.keys(projectsTypesObject).map(key => ({
                label: projectsTypesObject[key].name,
                value: key,
            }));

            projectsTypes.sort(sortByLabel);

            this.setState({
                projectsTypes: projectsTypes,
            });
        });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val() ? snapshot.val() : [];

            const skills = Object.keys(skillsObject).map(key => ({
                label: skillsObject[key].name,
                value: key,
            }));

            skills.sort(sortByLabel);

            this.setState({
                skills: skills,
            });
        });

        this.props.firebase.staff().on('value', snapshot => {
            const staffObject = snapshot.val() ? snapshot.val() : [];

            const staff = Object.keys(staffObject).map(key => ({
                label: [staffObject[key].firstName, staffObject[key].lastName].join(' '),
                value: key,
            }));

            staff.sort(sortStaffByLabel);

            this.setState({
                staff: staff,
                loading: false,
            });
        });
    };

    onSubmit = event => {
        this.addProject(event, false);
    };

    onSubmitAndContinue = event => {
        this.addProject(event, true);
    };

    addProject = (event, _continue) => {
        const { title, projectNdaStatus, description, url, selectedSkills, projectsCategory, selectedProjectsTypes, projectPresentation, selectedStaff, projectManager, designEffort, developmentEffort  } = this.state;

        let key = this.props.firebase.projects().push().key;

        this.props.firebase
            .project(key).set({
            "title": title,
            "projectNdaStatus": projectNdaStatus,
            "description": description,
            "url": url,
            "skills": selectedSkills,
            "projectsCategory": projectsCategory,
            "projectsTypes": selectedProjectsTypes,
            "projectPresentation": projectPresentation,
            "staff": selectedStaff,
            "projectManager": projectManager,
            "designEffort": designEffort,
            "developmentEffort": developmentEffort,
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            if (!_continue) {
                this.props.history.push(ROUTES.PROJECTS);
            } else {
                this.getData();
            }
        })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            title,
            projectNdaStatus,
            description,
            url,
            skills,
            selectedSkills,
            projectsCategories,
            projectsCategory,
            projectsTypes,
            selectedProjectsTypes,
            projectPresentation,
            staff,
            selectedStaff,
            projectManager,
            designEffort,
            developmentEffort,
            error,
        } = this.state;

        const isInvalid = title === '';

        return (
            <form>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="title"
                        value={title}
                        onChange={this.onChange}
                        type="text"
                        placeholder="Project title"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">NDA status</span>
                    <Select
                        options={ndaStatus}
                        value={projectNdaStatus}
                        onChange={value => this.setState({ projectNdaStatus: value })}
                        placeholder="Select NDA status"
                        closeMenuOnSelect={true}
                        className="mb-3"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Project description</label>
                    <textarea
                        className="form-control"
                        id="description"
                        rows="3"
                        name="description"
                        value={description}
                        onChange={this.onChange}
                    >
                    </textarea>
                </div>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="url"
                        value={url}
                        onChange={this.onChange}
                        type="url"
                        placeholder="Project url"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project skills</span>
                    <Select
                        options={skills}
                        value={selectedSkills}
                        onChange={value => this.setState({ selectedSkills: value })}
                        placeholder="Select skills"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                        name="skills"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project category</span>
                    <Select
                        options={projectsCategories}
                        value={projectsCategory}
                        onChange={value => this.setState({ projectsCategory: value })}
                        placeholder="Select category"
                        closeMenuOnSelect={true}
                        className="mb-3"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project type(s)</span>
                    <Select
                        options={projectsTypes}
                        value={selectedProjectsTypes}
                        onChange={value => this.setState({ selectedProjectsTypes: value })}
                        placeholder="Select types"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control"
                        name="projectPresentation"
                        value={projectPresentation}
                        onChange={this.onChange}
                        type="url"
                        placeholder="Project presentation"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project staff</span>
                    <Select
                        options={staff}
                        value={selectedStaff}
                        onChange={value => this.setState({ selectedStaff: value })}
                        placeholder="Select staff"
                        closeMenuOnSelect={false}
                        className="mb-3"
                        isMulti
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Project manager</span>
                    <input
                        className="form-control"
                        name="projectManager"
                        value={projectManager}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Design man-hours:</span>
                    <input
                        className="form-control"
                        name="designEffort"
                        value={designEffort}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <span className="d-block mb-2">Development man-hours:</span>
                    <input
                        className="form-control"
                        name="developmentEffort"
                        value={developmentEffort}
                        onChange={this.onChange}
                        type="text"
                    />
                </div>
                <button className="btn btn-success" disabled={isInvalid} type="submit" onClick={this.onSubmit}>
                    Save
                </button>

                <button className="btn btn-primary float-right" disabled={isInvalid} type="submit" onClick={this.onSubmitAndContinue}>
                    Save and add another
                </button>

                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const ProjectAddForm = compose(
    withRouter,
    withFirebase,
)(ProjectAddFormBase);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProjectsAddPage);

// export default CategoryAddPage;

// export { CategoryAddForm };