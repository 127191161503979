import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.database();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** Staff API ***

    employee = uid => this.db.ref(`staff/${uid}`);

    staff = () => this.db.ref('staff');

    teamLeads = () => this.db.ref('staff').orderByChild("isTeamLead").equalTo(true);

    // *** Categories API ***

    categories = () => this.db.ref('category');

    category = (uid) => this.db.ref(`category/${uid}`);

    // *** Skills API ***

    skills = () => this.db.ref('skills');

    skill = (uid) => this.db.ref(`skills/${uid}`);

    // *** Projects API ***

    projects = () => this.db.ref('projects');

    project = (uid) => this.db.ref(`projects/${uid}`);

    // *** Projects types API ***

    projectsTypes = () => this.db.ref('projects-types');

    projectsType = (uid) => this.db.ref(`projects-types/${uid}`);

    // *** Projects categories API ***

    projectsCategories = () => this.db.ref('projects-categories');

    projectsCategory = (uid) => this.db.ref(`projects-categories/${uid}`);

    // *** Reports API ***

    reports = () => this.db.ref('reports');

    report = (uid) => this.db.ref(`reports/${uid}`);

    reportsByTeamLead = (teamLead) => this.db.ref('reports').orderByChild("teamLead").equalTo(teamLead);

    // models = () => this.db.ref('/');
}

export default Firebase;