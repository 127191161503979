import React from 'react';
import { Link } from 'react-router-dom';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from '../Session';


const Navigation = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser => (
                authUser ? <NavigationAuth email={authUser.email}/> : <NavigationNonAuth/>
            )}
        </AuthUserContext.Consumer>
    </div>
);

const NavigationAuth = ({email}) => (
    <nav className="navbar navbar-expand-lg navbar-light bg-white px-0">
        <Link className="navbar-brand" to={ROUTES.EVNE_STAFF}>EVNE</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
                <NavItem path={ROUTES.EVNE_STAFF} name="Evne staff" />
                <NavItem path={ROUTES.EVNE_PROJECTS} name="Evne projects" />
                <NavItem path={ROUTES.ACCOUNT} name="Account" />
                {email === "peter@parker.com" && <NavItem path={ROUTES.ADMIN} name="Admin" />}
                <li className="nav-item ml-auto">
                    <SignOutButton />
                </li>
            </ul>
        </div>
    </nav>
);

const NavigationNonAuth = () => (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <Link className="navbar-brand text-center w-100 mr-0" to={ROUTES.LOGIN}>EVNE</Link>
    </nav>
);

const NavItem = props => {
    const pageURI = window.location.pathname+window.location.search;
    const liClassName = (props.path === pageURI) ? "nav-item active" : "nav-item";
    const aClassName = props.disabled ? "nav-link disabled" : "nav-link";
    return (
        <li className={liClassName}>
            <Link to={props.path} className={aClassName}>
                {props.name}
                {(props.path === pageURI) ? (<span className="sr-only">(current)</span>) : ''}
            </Link>
        </li>
    );
};

export default Navigation;