import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

//import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import { AuthUserContext, withAuthorization } from '../Session';

const LoginPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1 className="text-center mb-5">Login</h1>
                <SignInForm />
            </div>
        )}
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                localStorage.setItem('email', email);
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.EVNE_STAFF);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <div className="row justify-content-center">
                <div className="col-12 col-md-4">
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <input
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={this.onChange}
                                type="text"
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                className="form-control"
                                name="password"
                                value={password}
                                onChange={this.onChange}
                                type="password"
                                placeholder="Password"
                            />
                        </div>
                        <div className="row justify-content-between no-gutters align-items-center">
                            <button disabled={isInvalid} type="submit" className="btn btn-primary">
                                Sign In
                            </button>
                            <PasswordForgetLink />
                        </div>
                        {error && <p>{error.message}</p>}
                    </form>
                </div>
            </div>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

const condition = authUser => authUser === null;

export default withAuthorization(condition)(LoginPage);

export { SignInForm };