import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";
import {sortByName} from "../../utils"

class SkillsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            skills: [],
            search: '',
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val() ? snapshot.val() : [];

            const skillsList = Object.keys(skillsObject).map(key => ({
                ...skillsObject[key],
                uid: key,
            }));

            skillsList.sort(sortByName);

            this.setState({
                skills: skillsList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.skills().off();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    deleteSkill = (uid) => {
        this.props.firebase
            .skill(uid).remove()
            .then(() => {

            })
            .catch(error => {
                this.setState({ error });
            });
    };

    render() {
        const { skills, search, loading } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <div className="row no-gutters align-items-center justify-content-between">
                                    <h1>Skills</h1>
                                    <div className="form-group mb-3 mb-md-0">
                                        <input
                                            className="form-control"
                                            name="search"
                                            value={search}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Search skill"
                                        />
                                    </div>
                                    <Link to={ROUTES.SKILLS_ADD}><button className="btn btn-outline-primary">Add skill</button></Link>
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <SkillsList skills={skills.filter(skill => skill.name && skill.name.toLowerCase().includes(search.toLowerCase()))} deleteSkill={this.deleteSkill}/>
                            </div>
                        </div>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const SkillsList = ({ skills, deleteSkill }) => (
    <table className="table">
        <thead className="thead-dark" >
        <tr>
            <th>Skill name</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {
            skills.length > 0 ? (
                skills.map(skill => (
                    <tr key={skill.uid}>
                        <td><Link to={'/admin/skills/' + skill.uid}>{skill.name}</Link></td>
                        <td><button className="btn btn-danger" onClick={() => deleteSkill(skill.uid)}>Delete</button></td>
                    </tr>
                    )
                )
            ) : (
                <tr>
                    <td className="text-center py-4" colSpan="2">Skills list is empty</td>
                </tr>
            )
        }
        </tbody>
    </table>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(SkillsPage);