import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";

const AdminPage = () => (
    <AuthUserContext.Consumer>
        {() => (
            <div className="container">
                <h1 className="mb-5">Admin panel</h1>
                <table className="table">
                    <tbody>
                        <tr>
                            <th><Link to={ROUTES.STAFF}>Staff</Link></th>
                            <th><Link to={ROUTES.STAFF_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.STAFF}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                        <tr>
                            <th><Link to={ROUTES.CATEGORY}>Category</Link></th>
                            <th><Link to={ROUTES.CATEGORY_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.CATEGORY}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                        <tr>
                            <th><Link to={ROUTES.SKILLS}>Skills</Link></th>
                            <th><Link to={ROUTES.SKILLS_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.SKILLS}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                        <tr>
                            <th><Link to={ROUTES.PROJECTS}>Projects</Link></th>
                            <th><Link to={ROUTES.PROJECTS_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.PROJECTS}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                        <tr>
                            <th><Link to={ROUTES.PROJECTS_TYPES}>Projects types</Link></th>
                            <th><Link to={ROUTES.PROJECTS_TYPES_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.PROJECTS_TYPES}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                        <tr>
                            <th><Link to={ROUTES.PROJECTS_CATEGORIES}>Projects categories</Link></th>
                            <th><Link to={ROUTES.PROJECTS_CATEGORIES_ADD}><button className="btn btn-success">Add</button></Link></th>
                            <th><Link to={ROUTES.PROJECTS_CATEGORIES}><button className="btn btn-warning">Change</button></Link></th>
                        </tr>
                    </tbody>
                </table>
            </div>
        )}
    </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser && authUser.email === "peter@parker.com";

export default withAuthorization(condition)(AdminPage);