import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import {sortByName, employeeSort, sortBySkillLabel} from "../../utils";

class EvneStaffPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            staff: [],
            categoriesList: [],
            skillsList: [],
            skillsByCategory: {},
            skillsFilter: [],
            skillsCategoryFilter: []
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val();

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                ...categoriesObject[key],
                uid: key,
            }));

            categoriesList.sort(sortByName);

            this.setState({
                categoriesList: categoriesList
            });
        });

        this.props.firebase.skills().on('value', snapshot => {
            const skillsObject = snapshot.val();

            const skillsList = Object.keys(skillsObject).map(key => ({
                ...skillsObject[key],
                uid: key,
            }));


            const categoriesList = this.state.categoriesList;

            let skillsByCategory = {};

            categoriesList.forEach(category => {
                skillsByCategory[category.uid] = skillsList.filter(skill => {return skill.category === category.uid}).sort(sortByName)
            });

            this.setState({
                skillsList: skillsList,
                skillsByCategory: skillsByCategory,
            });
        });


        this.props.firebase.staff().on('value', snapshot => {
            const staffObject = snapshot.val();
            // todo: when role based will implemented update skills
            const staffList = Object.keys(staffObject).map(key => ({
                firstName: staffObject[key].firstName,
                lastName: staffObject[key].lastName,
                skills: staffObject[key].skills ? localStorage.getItem('email') === 'peter@parker.com' ? staffObject[key].skills : staffObject[key].skills.filter((skillObj) => {return skillObj.point.value > 2}) : [],
                uid: key,
            }));

            this.setState({
                staff: staffList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.staff().off();
        this.props.firebase.categories().off();
        this.props.firebase.skills().off();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleInputChange = event => {
        const target = event.target;
        const checked = target.checked;
        const value = target.value;

        let skillsFilter = this.state.skillsFilter;

        if (checked && !skillsFilter.includes(value)) {
            skillsFilter.push(value)
        } else if (!checked){
            skillsFilter = skillsFilter.filter(skill => {return skill !== value})
        }

        this.setState({
            skillsFilter: skillsFilter
        });
    };

    filterStaff = staff => {
        const {skillsFilter} = this.state;
        if (skillsFilter.length === 0) {
            return staff.sort(employeeSort)
        } else {
            return staff.filter(employee => {return skillsFilter.every(skill => {return employee.skills && this.isStaffHasSkill(employee, skill)})}).sort(employeeSort)
        }
    };

    isStaffHasSkill = (employee, skill) => {
        return employee.skills.some(skillObj => {
            return skillObj.skill.value === skill;
        });
    };

    clearFilter = () => {
        this.setState({
            skillsFilter: []
        });
    };

    render() {
        const { staff, loading, categoriesList, skillsList, skillsByCategory, skillsFilter } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-md-3 col-12 mb-4 mb-md-0 d-flex align-items-center">
                                <button className="btn btn-danger" onClick={this.clearFilter}>Clear skills filter</button>
                            </div>
                            <div className="col-md-6 col-12">
                                <h1 className="text-center mb-0">Staff skills</h1>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-md-3 col-12 mb-4 mb-md-0">
                                <AccordionFilter categoriesList={categoriesList} skillsList={skillsList} handleInputChange={this.handleInputChange} skillsByCategory={skillsByCategory} skillsFilter={skillsFilter}/>
                            </div>
                            <div className="col-md-9 col-12">
                                <StaffList staff={this.filterStaff(staff)}/>
                            </div>
                        </div>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const AccordionFilter = ({categoriesList, skillsList, handleInputChange, skillsByCategory, skillsFilter}) => (
    <Accordion accordion={false}>
        {categoriesList.map(category => (
            <AccordionItem key={category.uid}>
                <AccordionItemTitle>
                    <h5>{category.name}</h5>
                </AccordionItemTitle>
                <AccordionItemBody>
                    {skillsByCategory[category.uid] &&
                    <ul className="list-group">
                        {skillsByCategory[category.uid].map(skill => (
                            <li className="list-group-item" key={skill.uid}>
                                <div className="ml-3">
                                    <input
                                        id={skill.uid}
                                        className="form-check-input"
                                        name={skill.name}
                                        type="checkbox"
                                        value={skill.uid}
                                        onChange={handleInputChange}
                                        checked={skillsFilter.indexOf(skill.uid) > -1}
                                    />
                                    <label className="form-check-label" htmlFor={skill.uid}>
                                        {skill.name}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                    }
                    {skillsByCategory[category.uid] && skillsByCategory[category.uid].length === 0 && <h6>There are no skills for current category yet</h6>}
                </AccordionItemBody>
            </AccordionItem>
        ))}
    </Accordion>
);

const StaffList = ({ staff }) => (
    <table className="table">
        <thead className="thead-dark">
        <tr>
            <th>Full name</th>
            <th>Skills</th>
        </tr>
        </thead>
        <tbody>
        {staff.map(employee => (
            <tr key={employee.uid}>
                <td>
                    <Link to={'/admin/staff/' + employee.uid + '/view'}>
                        {employee.firstName} {employee.lastName}
                    </Link>
                </td>
                <td>{ConvertSkills(employee.skills)}</td>
            </tr>
        ))}
        </tbody>
    </table>
);

const ConvertSkills = (skills) => {
    let sortedSkills = skills.sort(sortBySkillLabel);
    return sortedSkills.map((skill, index) =>
        localStorage.getItem('email') !== 'peter@parker.com' && skill.point.value === 3 ?
            (<span key={index} className="text-danger mr-2">{skill['skill']['label']}</span>) :
            (<span key={index} className="mr-2">{skill['skill']['label']}</span>)
    )
};


const condition = authUser => !!authUser;

export default withAuthorization(condition)(EvneStaffPage);