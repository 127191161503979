import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext, withAuthorization } from '../Session';
import * as ROUTES from "../../constants/routes";
import {sortByName} from "../../utils";

class CategoryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            categories: [],
            search: '',
        };
    }

    componentDidMount() {
        this.setState({ loading: true });

        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObject = snapshot.val() ? snapshot.val() : [];

            const categoriesList = Object.keys(categoriesObject).map(key => ({
                ...categoriesObject[key],
                uid: key,
            }));

            categoriesList.sort(sortByName);

            this.setState({
                categories: categoriesList,
                loading: false,
            });
        });
    }

    componentWillUnmount() {
        this.props.firebase.categories().off();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    deleteCategory = (uid) => {
        this.props.firebase
            .category(uid).remove()
            .then(() => {

            })
            .catch(error => {
                this.setState({ error });
            });
    };

    render() {
        const { categories, search, loading } = this.state;

        return (
            <AuthUserContext.Consumer>
                {() => (
                    <div className="container">
                        <div className="row mb-5">
                            <div className="col-12 col-md-6">
                                <div className="row no-gutters align-items-center justify-content-between">
                                    <h2>Categories</h2>
                                    <div className="form-group mb-3 mb-md-0">
                                        <input
                                            className="form-control"
                                            name="search"
                                            value={search}
                                            onChange={this.onChange}
                                            type="text"
                                            placeholder="Search category"
                                        />
                                    </div>
                                    <Link to={ROUTES.CATEGORY_ADD}><button className="btn btn-outline-primary">Add category</button></Link>
                                </div>
                            </div>
                        </div>

                        {loading && <div>Loading ...</div>}
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <CategoriesList categories={categories.filter(category => category.name && category.name.toLowerCase().includes(search.toLowerCase()))} deleteCategory={this.deleteCategory}/>
                            </div>
                        </div>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

const CategoriesList = ({ categories, deleteCategory }) => (
    <table className="table">
        <thead className="thead-dark">
        <tr>
            <th>Category name</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        {
            categories.length > 0 ? (
                categories.map(category => (
                        <tr key={category.uid}>
                            <td><Link to={'/admin/category/' + category.uid}>{category.name}</Link></td>
                            <td><button className="btn btn-danger" onClick={() => deleteCategory(category.uid)}>Delete</button></td>
                        </tr>
                    )
                )
            ) : (
                <tr>
                    <td className="text-center py-4" colSpan="2">Categories list is empty</td>
                </tr>
            )
        }
        </tbody>
    </table>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(CategoryPage);